import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import {
    Collapse,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Navbar,
    NavbarToggler,
    Nav,
    NavLink
} from 'reactstrap'
import Logo from '../images/logo_small_alt.svg'
import Logo2 from '../images/logo_small.svg'
import Cookies from "universal-cookie";
import axios from "axios";
import PubSub from "pubsub-js";
import _ from "lodash";
import Attachment from "./PostItem";
import config from "../config";

// The Header creates links that can be used to navigate
// between routes.

const cookies = new Cookies();
let userDataUpdatedSubscriber;

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pathname: window.location.hash.substr(1, window.location.hash.length),
            isOpen: false,
            units: [],
            selectedUnitId: null,
            selectedUnit: {},
            renderedUnitsDropdownItems: [],
            profile_image: null,
            internal_app_image_url: null,
            userDropdownIsOpen: false
        };

        console.log(window.location.hash.substr(1, window.location.hash.length));

        this.logOut = this.logOut.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleUserDropdown = this.toggleUserDropdown.bind(this);
        this.toggleDocumentsDropdown = this.toggleDocumentsDropdown.bind(this);
        this.userDataUpdatedSubscriber = this.userDataUpdatedSubscriber.bind(this);
        this.renderUnitsDropdown = this.renderUnitsDropdown.bind(this);
        this.getUnitNameById = this.getUnitNameById.bind(this);
    }

    componentDidMount() {
        const _this = this;

        userDataUpdatedSubscriber = PubSub.subscribe('USER_DATA_UPDATED', _this.userDataUpdatedSubscriber);

        _this.userDataUpdatedSubscriber();
    }

    componentWillUnmount() {
        PubSub.unsubscribe(userDataUpdatedSubscriber);
    }

    userDataUpdatedSubscriber() {
        const _this = this;

        console.log("userDataUpdatedSubscriber !!!!");
        console.log("sessionStorage.getItem('sopotniki_me')", sessionStorage.getItem('sopotniki_me'));

        let selectedUnit = _.filter(JSON.parse(sessionStorage.getItem('sopotniki_me')).units, {'id': parseInt(sessionStorage.getItem('sopotniki_selected_unit_id'))})[0];


        console.log(selectedUnit);

        console.log(JSON.parse(sessionStorage.getItem('sopotniki_me')).units);
        console.log(sessionStorage.getItem('sopotniki_selected_unit_id'));

        _this.setState({
            profile_image: JSON.parse(sessionStorage.getItem('sopotniki_me')).volunteers.profile_image ? JSON.parse(sessionStorage.getItem('sopotniki_me')).volunteers.profile_image : null,
            internal_app_image_url: JSON.parse(sessionStorage.getItem('sopotniki_me')).volunteers.profile_image ? null : JSON.parse(sessionStorage.getItem('sopotniki_me')).volunteers.internal_app_image_url,
            units: JSON.parse(sessionStorage.getItem('sopotniki_me')).units,
            selectedUnitId: (sessionStorage.getItem('sopotniki_selected_unit_id')) ? sessionStorage.getItem('sopotniki_selected_unit_id') : JSON.parse(sessionStorage.getItem('sopotniki_me')).units[0].id,
            selectedUnit: selectedUnit ? selectedUnit : JSON.parse(sessionStorage.getItem('sopotniki_me')).units[0]
        });

        setTimeout(function () {
            _this.renderUnitsDropdown();
        }, 1);


    }

    getUnitNameById(id) {
        const _this = this;

        return _.find(_this.state.units, function (o) {
            return o.id == id
        });

    }

    renderUnitsDropdown() {
        const _this = this;

        if (!_this.state.units.length) return

        let renderedUnitsDropdownItems = [];

        _.each(_this.state.units, function (value, idx, arr) {
            console.log("value", value);

            if (value.disabled === 0 || (value.disabled === 1 && value.id === 56)) {
                renderedUnitsDropdownItems.push(<DropdownItem key={idx} onClick={(e) => {
                    e.preventDefault();
                    _this.setState({
                        selectedUnit: value,
                        dropdownIsOpen: false
                    });

                    sessionStorage.setItem('sopotniki_selected_unit_id', value.id);
                    setTimeout(function () {
                        window.location.reload(true);
                    }, 1);

                }}>{value.name + ' - ' + value.coveredMunicipalities}</DropdownItem>)
            }
        });

        _this.setState({
            renderedUnitsDropdownItems: renderedUnitsDropdownItems
        });

    }

    logOut() {
        const _this = this;

        axios.defaults.headers.common['authorization'] ? delete axios.defaults.headers.common['authorization'] : {};
        axios.defaults.headers.common['portal'] ? delete axios.defaults.headers.common['portal'] : {};
        axios.defaults.headers.common['unit_id'] ? delete axios.defaults.headers.common['unit_id'] : {};
        cookies.remove('sopotniki_volunteer', {path: '/'});
        sessionStorage.clear();

        _this.props.history.push('/prijava');
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleDropdown() {
        const _this = this;

        this.setState({
            dropdownIsOpen: !_this.state.dropdownIsOpen
        });
    }

    toggleDocumentsDropdown() {
        const _this = this;

        this.setState({
            documentsDropdownIsOpen: !_this.state.documentsDropdownIsOpen
        });
    }

    toggleUserDropdown() {
        const _this = this;

        this.setState({
            userDropdownIsOpen: !_this.state.userDropdownIsOpen
        });
    }

    render() {
        const _this = this;

        return (


            <Navbar className="navbar navbar-expand-lg navbar-dark" light expand="lg">
                <div className="container no-override">
                    <Link to='/objave' className="navbar-brand">
                        <img src={Logo} className="d-lg-inline mr-2 w-50"/>
                    </Link>

                    <NavbarToggler onClick={this.toggle} className="pull-right position-absolute"
                                   style={{right: '30px', top: '10px'}}/>
                    <Nav navbar/>


                    {/*<div className="collapse navbar-collapse justify-content-end" id="navbar-collapse">*/}
                    <Collapse isOpen={this.state.isOpen} navbar className="collapse navbar-collapse justify-content-end"
                              id="navbar-collapse">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to='/objave'
                                      className={(_this.state.pathname === '/objave' || _this.state.pathname === '/objave/urejanje') ? "nav-link nav-link--rounded" : "nav-link"}>Objave</Link>
                            </li>

                            <li className="nav-item">
                                <Link to='/fb-objave'
                                      className={(_this.state.pathname === '/fb-objave') ? "nav-link nav-link--rounded" : "nav-link"}>FB objave</Link>
                            </li>

                            <li className="nav-item">
                                <Link to='/koledar'
                                      className={_this.state.pathname === '/koledar' ? "nav-link nav-link--rounded" : "nav-link"}>E-koledar</Link>
                            </li>

                            <li className="nav-item">
                                {/*<Link to='/navodila'*/}
                                      {/*className={_this.state.pathname === '/navodila' ? "nav-link nav-link--rounded" : "nav-link"}>Navodila</Link>*/}

                                <Dropdown className="documentsDropdown" isOpen={_this.state.documentsDropdownIsOpen} toggle={_this.toggleDocumentsDropdown}>
                                    <DropdownToggle className="nav-link">
                                        Dokumenti <i className="fa fa-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="documentsDropdownMenu">
                                        <DropdownItem>
                                            <Link to='/dokumenti?document=prostovoljski_dan'>Prostovoljski dan</Link>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Link to='/dokumenti?document=navodila_za_prostovoljce'>Navodila za prostovoljce</Link>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Link to='/dokumenti?document=pravila_prostovoljskega_dela'>Pravila prostovoljskega dela</Link>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Link to='/dokumenti?document=eticni_kodeks_v_slikah'>Etični kodeks v slikah</Link>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Link to='/dokumenti?document=navodila_prostovoljci_korona'>Navodila (COVID-19)</Link>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Link to='/dokumenti?document=prirocnik_mobilna_aplikacija'>Priročnik Mobilna aplikacija Sopotniki 2.0</Link>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Link to='/dokumenti?video=IwmGOr8SyzM'>Video Varen vstop in izstop iz vozila</Link>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>


                            </li>

                            <li className="nav-item">

                                {_this.state.units.length > 1 &&
                                <Dropdown className="unitPicker" isOpen={_this.state.dropdownIsOpen} toggle={_this.toggleDropdown}>
                                    <DropdownToggle className="btn-pill btn-pill-sm btn-pill-info">
                                        {_this.state.selectedUnit.name} <i className="fa fa-chevron-down"></i>
                                    </DropdownToggle>
                                    {_this.state.renderedUnitsDropdownItems.length > 0 &&
                                    <DropdownMenu>
                                        {_this.state.renderedUnitsDropdownItems}
                                    </DropdownMenu>
                                    }
                                </Dropdown>
                                }

                            </li>
                            <li className="nav-item">


                                <Dropdown className="userDropdown" isOpen={_this.state.userDropdownIsOpen} toggle={_this.toggleUserDropdown}>
                                    <DropdownToggle className="nav-link">
                                        <img
                                            src={(_this.state.profile_image ? (config.apiRoot + config.actions.getFile + btoa(encodeURI("https://drive.google.com/thumbnail?authuser=0&sz=w40&id=" + _this.state.profile_image))) : _this.state.internal_app_image_url)} className="mr-1" style={{width: '40px'}}/>
                                        <i className="fa fa-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <Link onClick={(e) => {
                                                e.preventDefault();
                                                _this.logOut()
                                            }} to='/prijava'><i className="fa fa-sign-out"></i> Odjava
                                            </Link>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>

                            </li>
                        </ul>

                        {/*</div>*/}
                    </Collapse>
                </div>


            </Navbar>

        )
    }
}

export default withRouter(Header)
